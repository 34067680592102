import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import ReactPlayer from "react-player"

const RedefiningTravel = ({ data }) => {
  const images = {
    subHeader: convertToBgImage(getImage(data.subHeader)),
  }
  return (
    <Layout>
      <Seo title="Success stories | Redefining Travel" />
      <section>
        <div className="main">
          <BackgroundImage
            {...images.subHeader}
            className="sap-header sub-success"
          >
            <div className="container">
              <div className="sap-header-content">
                <div className="img">
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/sap/clients/acorn.png"
                    width={300}
                    alt="techpac"
                  />
                </div>
                <div className="success-header-title">Redefining Travel</div>
                <div className="header-description">
                  Empowering travel companies to stay ahead of the game and
                  provide exceptional experiences by becoming intelligent
                  enterprises.
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="sub-success-content">
            <div className="row">
              <div className="col-md-6 sub-success-details">
                <p>
                  Acorn Travels is an aviation and travel conglomerate in Sri
                  Lanka that provides comprehensive travel solutions for
                  corporate and leisure travel both globally and domestically.
                </p>
                <p>
                  Tech Pacific successfully implemented the complete SAP
                  Business ByDesign Cloud ERP solution excepting the production
                  module empowering Acorn Travels to reach new levels of
                  insight, flexibility, effectiveness and service for travelers
                  while being more efficient in managing costs and generating
                  revenue streams by providing new and innovative services to
                  customers at competitive prices. The implementation
                  encompassed 9 companies including 2 overseas and over 25
                  integrations with suppliers and banks and was completed within
                  a span of just 7 months.
                </p>
                <ReactPlayer
                  controls={true}
                  url="https://www.youtube.com/watch?v=nB__KxxcoTw"
                />
              </div>
              <div className="col-md-6 sub-success-points">
                <div className="sub-success-items">
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      Client
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">Acorn Travels</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      SAP Solution
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4"> SAP Business ByDesign</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      Mode
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">
                      Offshore implementation
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      Start date
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">August 2020</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      End date
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">March 2021</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="get-in-touch blue d-flex align-items-center justify-content-center">
          <div className="get-in-touch-content text-center container">
            <p>Get In Touch With Our Experts</p>
            <Link to="/contact">
              <button
                className="btn btn-primary blue-contact-footer"
                type="button"
              >
                Let's Get to Work
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query subSuccess1 {
    subHeader: file(relativePath: { eq: "success-stories/subHeader.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default RedefiningTravel
